import { objHasField, maybeProp, toMaybe } from 'its-js-utility';
import { dateToMaybe } from '../../lib';
import mapMaybeValidatedContexts from './mapMaybeValidatedContexts';
import mapTaxonomy from './mapTaxonomy';
import mapClassification from './mapClassification';

const validArchivedTaxonomyObj = taxonomy =>
  objHasField('documentType', taxonomy);

const mapArchivedTaxonomy = documentItem =>
  objHasField('taxonomy', documentItem)
    .flatMap(maybeProp('taxonomy'))
    .flatMap(validArchivedTaxonomyObj)
    .map(mapTaxonomy);

const mapReceivedAt = documentItem =>
  objHasField('documentMetadata', documentItem)
    .flatMap(maybeProp('documentMetadata'))
    .flatMap(maybeProp('receivedAt'))
    .map(date => new Date(date))
    .flatMap(dateToMaybe);

const mapArchivedContent = (documentItem, sections) => {
  const piiSectionObj = sections.find(x => x.mdmExternalId === documentItem.piiSection);
  return {
    taxonomy: mapArchivedTaxonomy(documentItem),
    notes: documentItem.notes || '',
    receivedAt: mapReceivedAt(documentItem),
    classification: mapClassification(documentItem).orJust('Not found'),
    piiSection: documentItem.piiSection,
    piiSectionText: piiSectionObj ? `${piiSectionObj.code} - ${piiSectionObj.name}` : 'Not PII',
  };
};

const compare = (a, b) => {
  if (a.date > b.date) return 1;
  if (a.date < b.date) return -1;
  return 0;
};

const mapArchivedDocument = ({ documentItem, sections, ...rest }) => {
  const sortedHistory = documentItem.history.sort(compare);
  const archived = sortedHistory[0];
  const lastUpdate = sortedHistory.filter(x => x.method === 'Update').pop();

  return {
    id: documentItem.id,
    contextList: mapMaybeValidatedContexts(rest),
    content: mapArchivedContent(documentItem, sections),
    archivedBy: toMaybe(
      lastUpdate ? lastUpdate.user.name : archived ? archived.user.name : null
    ),
    originallyArchivedBy: toMaybe(archived),
    archivedAt: toMaybe(
      lastUpdate ? lastUpdate.date : archived ? archived.date : null
    ).flatMap(dateStr => dateToMaybe(new Date(dateStr))),
  };
};

export default mapArchivedDocument;
