import { objHasField, maybeProp } from 'its-js-utility';
import mapTaxonomy from './mapTaxonomy';

const validTaxonomyObj = taxonomy =>
  objHasField('documentType', taxonomy);

const mapMaybeTaxonomy = analysisObj =>
  objHasField('taxonomy', analysisObj)
    .flatMap(maybeProp('taxonomy'))
    .flatMap(validTaxonomyObj)
    .map(mapTaxonomy);

export default mapMaybeTaxonomy;
