import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'its-js-utility';
import { formatTime, maybeShape } from '../../lib';
import Maybe from '../Maybe';

const ReceivedAt = ({ maybeDate, nothing }) => (
  <Maybe type={maybeDate}
         nothing={nothing}
         just={date => (
           <span>
             {formatTime(date)} / {formatDate(date)}
           </span>
         )} />
);

ReceivedAt.propTypes = {
  maybeDate: PropTypes.shape(maybeShape).isRequired,
  nothing: PropTypes.func.isRequired
};

export default ReceivedAt;
