import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { remoteDataToMaybe, toAction, toMaybe } from 'its-js-utility';
import { ConfirmMessage, Modal, ModalMessage } from 'its-react-ui';
import cn from 'classnames';
import * as office from '../../apis/office';
import { getAadToken } from '../../appAuth/msalAuth';
import { PageTitle } from '../../components';
import ValidationErrors from '../../components/ValidationErrors';
import { CLOSE_MODAL_MESSAGE } from '../../containers/App/constants';
import { showIsNoKeywordsModal } from '../../containers/App/ModalActions';
import {
  ARCHIVE_DOCUMENT_CANCEL,
  ARCHIVE_DOCUMENT_EXCLUDING_INVALID_ATTACHMENTS,
  IS_PII_CHECKED_CHANGED,
  PII_SECTION_CHANGED
} from '../../containers/DocumentPage/constants';
import UnarchivePage from '../../containers/UnarchivePage/index';
import User from '../../containers/User/index';
import ArchiveBar from './document/ArchiveBar';
import Content from './document/Content';
import InsuranceContexts from './document/InsuranceContexts';
import {
  documentModelShape, getMailbox,
  maybeShape,
  remoteDataShape,
  validationShape,
  PiiSuggestionStatus,
  usePiiSuggestion,
} from '../../lib';
import { useFeatureFlag, FeatureEnum } from '../../providers/FeatureFlagProvider';
import styles from './document/Document.module.scss';
import { routes } from '../../App/Router';

const getClassNames = verticalListing =>
  cn({
    [styles.content]: true,
    [styles.marginForFixedArchiveBar]: verticalListing,
  });

const getHeader = editMode =>
  editMode
    ? <PageTitle icon="archivedDoc" title="ARCHIVED DOCUMENT" />
    : <PageTitle icon="doc" title="NEW DOCUMENT" />;

const getDocModel = docModel => ({
  ...docModel,
  note: docModel.note.trim()
});

const Document = props => {
  // <LEAPDEV-1180>
  /*
  useEffect(() => {
    if (!props.editMode) {
      office.hasInsifyCategory(window.Office.context.mailbox.item)
        .then(f => {
          if (f) {
            office.removeItemCategoryFromItem(window.Office.context.mailbox.item);
          }
        });
    }
  }, [props.editMode]);
 */
  const getDocumentData = state => {
    const {
      archivedPage,
      contextList,
      documentPage: { documentModel, isPiiChecked },
      user: { activeRole, user }
    } = state;
    const archivedDocumentRole = props.getArchivedDocumentRole(archivedPage.document.data);
    const maybeArchivedDocument = remoteDataToMaybe(archivedPage.document);
    const docModel = {
      ...documentModel,
      contexts: contextList.selectedContexts.orJust([]),
      applicationRoleName: props.editMode && archivedDocumentRole ? archivedDocumentRole.value : activeRole.value
    };
    return {
      archivedDocumentPiiSection: props.editMode && maybeArchivedDocument.isJust() ? maybeArchivedDocument.just().content.piiSection : null,
      archivedPage,
      docModel,
      isPiiChecked,
      remoteDataUser: user,
    };
  };
  const {
    archivedDocumentPiiSection, archivedPage, docModel, isPiiChecked, remoteDataUser
  } = useSelector(state => getDocumentData(state));

  const dispatch = useDispatch();

  const [isPiiClassifierVisible, setIsPiiClassifierVisible] = useState(false);
  const isPiiFeatureFlagEnabled = useFeatureFlag(FeatureEnum.PII);
  const [userSections, setUserSections] = useState([]);
  useEffect(() => {
    const maybeUser = remoteDataToMaybe(remoteDataUser);
    const user = maybeUser.just();
    const updatedUserSections =
      [{ value: null, displayName: 'Select section', selected: archivedDocumentPiiSection === null }]
        .concat(user.sections.map(section => ({
          value: section.mdmExternalId,
          displayName: `${section.code} - ${section.name}`,
          selected: section.mdmExternalId === archivedDocumentPiiSection
        })));

    setUserSections(updatedUserSections);
    setIsPiiClassifierVisible(true);

    if (archivedDocumentPiiSection) {
      dispatch(toAction(IS_PII_CHECKED_CHANGED, true));
      dispatch(toAction(PII_SECTION_CHANGED, archivedDocumentPiiSection));
    }
  }, [isPiiFeatureFlagEnabled, archivedDocumentPiiSection]);
  const [lastPiiStatus, setLastPiiStatus] = useState(PiiSuggestionStatus.NotAsked);
  const piiStatus = usePiiSuggestion(!props.editMode && isPiiFeatureFlagEnabled);
  useEffect(() => {
    if (piiStatus !== lastPiiStatus && piiStatus === PiiSuggestionStatus.Suggest && !isPiiChecked) {
      setLastPiiStatus(PiiSuggestionStatus.Suggest);
      dispatch(toAction(IS_PII_CHECKED_CHANGED, true));
    }
  }, [isPiiChecked, lastPiiStatus, piiStatus]);

  const onTogglePiiCheckbox = () => {
    if (isPiiChecked) {
      setUserSections(userSections.map(x => ({ ...x, selected: x.value === null })));
      dispatch(toAction(PII_SECTION_CHANGED, null));
    }
    dispatch(toAction(IS_PII_CHECKED_CHANGED, !isPiiChecked));
  };
  const onSelectPiiSection = selectedItem => {
    setUserSections(userSections.map(x => ({ ...x, selected: x.value === selectedItem.value })));
    dispatch(toAction(PII_SECTION_CHANGED, selectedItem.value));
  };

  useEffect(() => {
    if (props.documentModel.note.trim() !== '') {
      props.updateNoteKeywords(props.documentModel.note);
    }
  }, [props.documentModel.note, props.documentModel.documentType]);

  const [showUnArchive, setIsUnArchiveVisibility] = useState(false);

  const handleArchiveDocument = () => {
    const messageId = office.getRestifiedItemId(window.Office);
    const mailbox = getMailbox(remoteDataUser);
    const { item } = window.Office.context.mailbox;
    getAadToken()
      .then(aadToken => {
        props.archiveDocument({
          mailbox,
          messageId,
          docModel: getDocModel(docModel),
          aadToken,
          item,
        });
      });
  };
  const handleUpdateDocument = () => {
    const messageId = office.getRestifiedItemId(window.Office);
    const { item } = window.Office.context.mailbox;
    getAadToken()
      .then(aadToken => {
        props.updateDocument({
          messageId,
          aadToken,
          docModel: getDocModel(docModel),
          archivedPage,
          item,
        });
      });
  };

  const closeModal = () => Promise.resolve(dispatch(toAction(CLOSE_MODAL_MESSAGE)))
    .then(() => props.history.push(routes.archived));
  const confirmAndCloseModal = () => Promise.resolve(dispatch(toAction(CLOSE_MODAL_MESSAGE)))
    .then(() => dispatch(toAction(ARCHIVE_DOCUMENT_EXCLUDING_INVALID_ATTACHMENTS)));
  const cancelAndCloselModal = () => Promise.resolve(dispatch(toAction(CLOSE_MODAL_MESSAGE)))
    .then(() => dispatch(toAction(ARCHIVE_DOCUMENT_CANCEL)));

  const tagSearchResultFromSuggested = (searchResult, suggestedKeywords) => {
    const suggested = remoteDataToMaybe(suggestedKeywords).orJust([]).map(sk => sk.value);
    const tagged = searchResult.orJust([]).map(g => ({ ...g, isSuggestion: suggested.includes(g.value) }));
    return toMaybe(tagged.sort((first, second) =>
      first.displayName.toUpperCase() < second.displayName.toUpperCase() ? -1 : 1));
  };

  const sortedKeywords = props.documentModel.keywords.sort((first, second) =>
    first.displayName.toUpperCase() < second.displayName.toUpperCase() ? -1 : 1);

  return (
    <>
      <User />
      <div className={styles.container}>
        <div className={getClassNames(props.verticalListing)}>
          {getHeader(props.editMode)}
          <Content
            addKeyword={props.addKeyword}
            addKeywords={props.addKeywords}
            classifications={props.classifications}
            classificationsChanged={props.classificationsChanged}
            deleteKeywordClicked={props.deleteKeywordClicked}
            documentTypes={props.documentTypes}
            documentTypesChanged={props.documentTypesChanged}
            keywords={sortedKeywords}
            matchedWords={props.documentAnalysis.matchedWords}
            note={props.documentModel.note}
            noteChanged={props.noteChanged}
            onNoKeyWords={() => {
              const selectedDocType = props.documentTypes.find(a => a.selected).displayName;
              showIsNoKeywordsModal(dispatch, selectedDocType);
            }}
            receivedAt={props.receivedAt}
            searchResult={tagSearchResultFromSuggested(props.searchResult, props.documentModel.suggestedKeywords)}
            searchString={props.searchString}
            searchStringChanged={props.searchStringChanged}
            toggleAddKeywords={props.toggleAddKeywords}
            validation={props.validation}
            isPiiClassifierVisible={isPiiClassifierVisible}
            isPiiChecked={isPiiChecked}
            onTogglePiiCheckbox={onTogglePiiCheckbox}
            onSelectPiiSection={onSelectPiiSection}
            userSections={userSections}
          />
          <InsuranceContexts
            isGrouped={props.documentAnalysis.isGrouped}
            contextList={props.documentAnalysis.contextList}
            validation={props.validation}
            verticalListing={props.verticalListing}
            toggleContextListing={props.toggleContextListing}
          />
        </div>
        <div className={styles.archiveMargin}>
          {props.validation.showValidationResult
            ? <ValidationErrors fields={props.validation.fields} />
            : null}
          <ArchiveBar hasArchivedDocument={props.hasArchived}
                      userCanUnArchive={props.userCanUnArchive}
                      fixed={props.verticalListing}
                      editMode={props.editMode}
                      updateDocument={handleUpdateDocument}
                      showValidationResult={props.showValidationResult}
                      archiveDocument={handleArchiveDocument}
                      onLetsUnarchive={() => setIsUnArchiveVisibility(true)}
                      validation={props.validation}
                      invalidAttachments={props.invalidAttachments}
          />
        </div>
      </div>
      {showUnArchive && (
        <Modal show>
          <UnarchivePage
            onClose={() => setIsUnArchiveVisibility(false)}
          />
        </Modal>
      )}
      {props.showDocumentExist && (
        <Modal show>
          <ModalMessage
            header="Document has already been archived"
            content="This document has been archived from another client after it was opened by you. Press close to load the archived document"
            onClose={() => closeModal()}
          />
        </Modal>
      )}
      {props.invalidAttachments.length > 0 && (
        <Modal show>
          <ConfirmMessage
            header="This e-mail contains one or more attachments that might be a security risk"
            confirmText="Exclude file(s) and continue"
            cancelText="Cancel archiving"
            onConfirm={() => confirmAndCloseModal()}
            onCancel={() => cancelAndCloselModal()}>
            <>
              You may only archive the e-mail without the following attachment(s):
              <ul>
                {props.invalidAttachments && props.invalidAttachments.map(a =>
                  <li key={a}>{a}</li>)}
              </ul>
            </>
          </ConfirmMessage>
        </Modal>
      )}
    </>
  );
};

Document.propTypes = {
  addKeyword: PropTypes.func.isRequired,
  addKeywords: PropTypes.bool.isRequired,
  archiveDocument: PropTypes.func.isRequired,
  classifications: PropTypes.array.isRequired,
  classificationsChanged: PropTypes.func.isRequired,
  deleteKeywordClicked: PropTypes.func.isRequired,
  documentAnalysis: PropTypes.shape(remoteDataShape).isRequired,
  documentModel: PropTypes.shape(documentModelShape).isRequired,
  documentTypes: PropTypes.array.isRequired,
  documentTypesChanged: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  hasArchived: PropTypes.shape(remoteDataShape).isRequired,
  history: PropTypes.object.isRequired,
  receivedAt: PropTypes.shape(maybeShape).isRequired,
  searchResult: PropTypes.shape(maybeShape).isRequired,
  searchString: PropTypes.string.isRequired,
  searchStringChanged: PropTypes.func.isRequired,
  showDocumentExist: PropTypes.bool.isRequired,
  invalidAttachments: PropTypes.arrayOf(PropTypes.string),
  showValidationResult: PropTypes.func.isRequired,
  toggleAddKeywords: PropTypes.func.isRequired,
  toggleContextListing: PropTypes.func.isRequired,
  updateDocument: PropTypes.func.isRequired,
  userCanUnArchive: PropTypes.bool.isRequired,
  validation: PropTypes.shape(validationShape).isRequired,
  verticalListing: PropTypes.bool.isRequired,
  getArchivedDocumentRole: PropTypes.func.isRequired,
  noteChanged: PropTypes.func.isRequired,
  updateNoteKeywords: PropTypes.func.isRequired,
};

export default Document;
