import { buildQueryString } from 'its-js-utility';
import { respOkOrThrow, jsonOrThrow } from './core';
import { getItsToken } from '../appAuth/msalAuth';

const authorizedFetch = (url, options = {}) =>
  getItsToken()
    .then(token =>
      fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }));

const authorizedReq = (url, method, headers) =>
  authorizedFetch(url, { method, headers });

const authorizedPost = (url, body, headers) =>
  authorizedFetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify(body)
  });

const authorizedDelete = (url, headers) =>
  authorizedFetch(url, {
    headers,
    method: 'DELETE',
  });

const authorizedPut = (url, body, headers) =>
  authorizedFetch(url, {
    headers,
    method: 'PUT',
    body: JSON.stringify(body)
  });

const getMessage = message => {
  if (message instanceof Error) {
    return `message: ${message.message}, stack: ${message.stack}`;
  }
  if (typeof message !== 'string') {
    return JSON.stringify(message);
  }

  return message;
};

// NOTE: Just swallow log err if we at some point log unauthenticated
export const logMessage = msg =>
  authorizedPost('/api/log', { message: getMessage(msg) }, {})
    .catch(err => err);

export const consoleLogMessage = msg =>
  // eslint-disable-next-line no-console
  console.log(`msg: ${msg}`);

export const get = (url, params = [], headers = {}) =>
  authorizedReq(`${url}${buildQueryString(params)}`, 'GET', headers)
    .then(jsonOrThrow)
    .catch(err => {
      logMessage(`get ${url} params ${params} failed -- ${getMessage(err)}`);
      throw new Error(err);
    });

export const post = (url, headers, body) =>
  authorizedPost(url, body, headers)
    .then(respOkOrThrow)
    .catch(err => {
      logMessage(`post ${url} body ${JSON.stringify(body)} failed -- ${getMessage(err)}`);
      throw err;
    });

export const put = (url, headers, body) =>
  authorizedPut(url, body, headers)
    .then(respOkOrThrow)
    .catch(err => {
      logMessage(`post ${url} body ${JSON.stringify(body)} failed -- ${getMessage(err)}`);
      throw new Error(err);
    });

export const remove = (url, headers) =>
  authorizedDelete(url, headers)
    .then(respOkOrThrow)
    .catch(err => {
      logMessage(`delete ${url} failed -- ${getMessage(err)}`);
      throw new Error(err);
    });

export const head = (url, params = [], headers = {}) =>
  authorizedReq(`${url}${buildQueryString(params)}`, 'HEAD', headers)
    .then(respOkOrThrow);
