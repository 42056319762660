import { toMaybe } from 'its-js-utility';
import mapRoles from './mapRoles';

// Note: User obj should contain name, email and roles fields with valid values.
// If not return defaults
const mapUser = ({
  name, mailbox, roles, userMailbox, sections
}) => ({
  name: toMaybe(name).orJust('Unknown user'),
  mailbox: toMaybe(mailbox).orJust('email claim empty, or in shared mailbox'),
  roles: toMaybe(roles).map(mapRoles).orJust([]),
  userMailbox: toMaybe(userMailbox).orJust('email claim empty, or in shared mailbox'),
  sections
});

export default mapUser;
