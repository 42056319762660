import { pipe, map } from 'ramda';
import { objHasField, toMaybe, maybeProp } from 'its-js-utility';
import mapContext from './mapContext';

const mapToMaybe = mapper =>
  pipe(mapper, toMaybe);

const mapValidatedContexts = validatedContexts =>
  map(
    ({ insuranceContext, restricted, portalLink }) =>
      mapContext(insuranceContext, restricted, portalLink),
    validatedContexts
  );

const mapMaybeValidatedContexts = response =>
  objHasField('validatedContexts', response)
    .flatMap(maybeProp('validatedContexts'))
    .flatMap(mapToMaybe(mapValidatedContexts));

export const mapMaybeInsuranceContextGroups = response =>
  toMaybe(objHasField('insuranceContextGroups', response)
    .flatMap(maybeProp('insuranceContextGroups'))
    .flatMap(r => r)
    .map(r => r.flatMap(r2 => ({
      restricted: false,
      insuranceContext: r2,
    })))
    .flatMap(mapToMaybe(mapValidatedContexts)));

export default mapMaybeValidatedContexts;
