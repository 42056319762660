import { buildQueryString } from 'its-js-utility';
import {
  get, post, put, remove,
} from './base';
import { jsonOrThrow, respOkOrThrow } from './core';
import {
  mapArchivedDocument,
  mapClassifications,
  mapDocumentAnalysis,
  mapDocumentTypes,
  mapSearchResult,
  mapUpdateDocumentTags,
} from './mappers';
import { mapDocumentGroupedAnalysis } from './mappers/mapDocumentAnalysis';
import { mapMaybeInsuranceContextGroups } from './mappers/mapMaybeValidatedContexts';
import { isUnderwriter } from '../lib';

// Used only in src/containers/DocumentPage/sagas.js - getClassificationsAndDocumentTypesWorkerSaga
// If roles distinguish classifications, add role to params and endpoint
export const getMappedClassifications = () =>
  get('/api/classifications')
    .then(mapClassifications);

export const getMappedDocumentTypes = role =>
  get(`/api/documenttypes${buildQueryString([['role', role]])}`)
    .then(mapDocumentTypes);

export const getFavorites = () => get('/api/user/favorites');
export const addFavorite = favoriteId =>
  post('/api/user/favorites', {}, { documentTypeId: favoriteId });
export const removeFavorite = favoriteId => remove(`/api/user/favorites/${favoriteId}`);

const getAnalysis = (emailId, aadToken, mailBox, role) =>
  post('/api/analysis', {}, {
    emailId,
    externalToken: aadToken,
    mailBox,
    role,
  })
    .then(jsonOrThrow)
    .then(analysis => mapDocumentAnalysis(analysis));

const getGroupAnalyse = (emailId, aadToken, mailBox, role) =>
  post('/api/analysis/group', {}, {
    emailId,
    externalToken: aadToken,
    mailBox,
    role,
  })
    .then(jsonOrThrow)
    .then(mapDocumentGroupedAnalysis);

export const fetchAnalysis = (emailId, aadToken, mailBox, role) =>
  getAnalysis(emailId, aadToken, mailBox, role);

export const fetchGroupAnalyse = (emailId, aadToken, mailBox, role) =>
  getGroupAnalyse(emailId, aadToken, mailBox, role);

export const getUpdateTaxonomy = (emailId, aadToken, mailBox, role, documentType) =>
  post('/api/analysis/documenttype', {}, {
    emailId,
    externalToken: aadToken,
    mailBox,
    role,
    documentType,
  })
    .then(jsonOrThrow)
    .then(mapUpdateDocumentTags);

export async function getIsArchived(id, aadToken, mailBox) {
  const resp = await post('/api/emailarchived/', {}, {
    token: aadToken,
    messageId: id,
    mailBox,
  });
  const j = await jsonOrThrow(resp);
  if (!j) {
    const err = new Error('Not archived');
    err.status = 404;
    throw err;
  }
}

export async function getDocument(emailId, aadToken, mailBox) {
  const resp = await put('/api/email/', {}, {
    messageId: emailId,
    token: aadToken,
    mailBox,
  });

  const j = await jsonOrThrow(resp);
  return mapArchivedDocument(j);
}

export const deleteDocument = deleteDocumentRequest =>
  post('/api/email/delete', {}, { ...deleteDocumentRequest })
    .then(respOkOrThrow);

const groupSearch = (query, role) =>
  get(`/api/search/grouped${buildQueryString([
    ['searchString', query],
    ['role', role],
  ])}`)
    .then(insuranceContextGroups =>
      mapMaybeInsuranceContextGroups(({ insuranceContextGroups })));

const listSearch = (query, searchInFinished, role) =>
  get(`/api/search${buildQueryString([
    ['searchString', query],
    ['searchInFinished', searchInFinished],
    ['role', role],
  ])}`)
    .then(mapSearchResult);

export const search = (query, searchInFinished, role, forceListResult) =>
  (forceListResult || !isUnderwriter(role))
    ? listSearch(query, searchInFinished, role)
    : groupSearch(query, role);

export const getPiiSuggestion = async (emailId, mailBox, externalToken) => post('/api/analysis/pii', {}, {
  emailId,
  mailBox,
  externalToken,
});
