import { Nothing } from 'monet';
import { includes, map, prop } from 'ramda';
import { maybeFilter } from 'its-js-utility';

// NOTE: Filter tags on suggested tags with same document type
const filterBySelectedKeywords = (analysisKeywords, documentType, keywords) => {
  const noAnalysisKeywords = analysisKeywords && analysisKeywords.length === 0;
  if (noAnalysisKeywords || documentType.disabled) {
    return Nothing();
  }

  const validKeyWords = keywords.filter(k => k.docType === documentType.value);

  return maybeFilter(keyword => {
    const mappedKeywords = map(prop('value'), analysisKeywords);
    return includes(keyword.value, mappedKeywords);
  }, validKeyWords);
};

export default filterBySelectedKeywords;
