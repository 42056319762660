import React, { useEffect, useState } from 'react';
import { filter, propEq } from 'ramda';
import PropTypes from 'prop-types';
import { toMaybe } from 'its-js-utility';
import {
  ContentRow,
  DropdownField,
  InlineTextEdit,
  KeywordsRow,
  PiiClassifier
} from 'its-react-ui';
import {
  mdiFileDocumentBoxOutline, mdiStarOutline, mdiClockOutline,
} from '@mdi/js';
import {
  Maybe, SelectOption, Validation, DocumentType
} from '../../../types';
import {
  ReceivedAt,
} from '../../../components';
import { maybeShape, selectOptionShape } from '../../../lib';
import styles from './content/Content.module.scss';
import { useFavorites } from '../../../containers/DocumentPage/useFavorites';

const getError = (validation: Validation, fieldName: string) => {
  const field = validation.fields[fieldName] || {};
  return {
    ...field,
    show: validation.showValidationResult,
  };
};

const getDocTypeTooltip = (matchedWords?: string) => {
  const text = matchedWords ? matchedWords.trim() : '';
  return (text.length > 0) ? { show: true, text } : { show: false, text: '' };
};

const filterDisabled = filter(propEq(false, 'disabled'));

// Most of the props are defined in its-react-ui, we should convert it to TS in the future at some point as well.
interface Props {
  validation: Validation;
  addKeyword: (...args: any[]) => any,
  addKeywords: boolean;
  classifications: any[];
  classificationsChanged: (classification: any) => void;
  deleteKeywordClicked: (...args: any[]) => any,
  documentTypes: DocumentType[];
  documentTypesChanged: (documentType: any) => void;
  keywords: any[];
  note: string;
  onNoKeyWords: (...args: any[]) => any;
  receivedAt: Maybe,
  searchResult: Maybe,
  searchString: string;
  searchStringChanged: (value: any) => void;
  toggleAddKeywords: (...args: any[]) => any,
  noteChanged: (text: string) => void;
  matchedWords?: string;
  isPiiClassifierVisible: boolean;
  isPiiChecked: boolean;
  onTogglePiiCheckbox: (...args: any[]) => any,
  onSelectPiiSection: (section: any) => void;
  userSections: SelectOption[],
}
const Content = ({ validation, ...props }: Props) => {
  const [focusNote, setFocusNote] = useState(false);
  const [shouldFocusNote, setShouldFocusNote] = useState(false);
  const [favorites, add, remove] = useFavorites();

  const handleInlineTextEditOnFocus = () => {
    if (!props.addKeywords) {
      props.toggleAddKeywords(true);
      setShouldFocusNote(true);
    }
  };
  const handleInlineTextEditOnBlur = () => {
    if (focusNote) {
      setFocusNote(false);
    }
  };

  useEffect(() => {
    if (shouldFocusNote) {
      setShouldFocusNote(false);
      setFocusNote(true);
    }
  }, [props.addKeywords]);

  const onFavorite = (option: DocumentType) => {
    if (favorites.includes(option.id)) {
      remove(option.id);
    } else {
      add(option.id);
    }
  };

  const selectedDisabledDocType = props.documentTypes.find(x => x.selected && x.disabled);

  return (
    <div className={styles.container}>
      <ContentRow
        icon={mdiFileDocumentBoxOutline}
        title="Doc. Type"
        validation={getError(validation, 'documentType')}
        tooltip={getDocTypeTooltip(props.matchedWords)}
      > <DropdownField
          values={filterDisabled(props.documentTypes)}
          onSelect={props.documentTypesChanged}
          search={{
            enableSearch: true,
            objectFieldName: 'displayName',
            objectSelectedFieldName: undefined,
          }}
          onFavorite={onFavorite}
          withFavorites
          favorites={favorites}
          placeholder={selectedDisabledDocType?.value}
        />
      </ContentRow>
      <ContentRow
        icon={mdiStarOutline}
        title="Classification"
        validation={getError(validation, 'classification')}>
        <DropdownField values={props.classifications}
                       onSelect={props.classificationsChanged} />
      </ContentRow>
      <ContentRow icon={mdiClockOutline} title="Time / Date">
        <ReceivedAt
          maybeDate={props.receivedAt}
          nothing={() => <span>Failed to find date</span>} />
      </ContentRow>
      {props.isPiiClassifierVisible && (
      <PiiClassifier
        isChecked={props.isPiiChecked}
        onToggle={props.onTogglePiiCheckbox}
        dropDownValues={props.userSections}
        onSelectDropDownValue={props.onSelectPiiSection}
      />
      )}
      <InlineTextEdit
        autoFocus={focusNote}
        id="InlineTextEdit"
        maxTextLength={160}
        text={props.note}
        onChange={props.noteChanged}
        placeholder="Click to add details where keywords are not sufficient"
        title="Notes"
        onFocus={handleInlineTextEditOnFocus}
        onBlur={handleInlineTextEditOnBlur}
        textareaRows={4}
      />
      <KeywordsRow
        addKeyword={props.addKeyword}
        addKeywords={props.addKeywords}
        deleteKeywordClicked={props.deleteKeywordClicked}
        onNoKeyWords={props.onNoKeyWords}
        searchResult={props.searchResult}
        searchString={props.searchString}
        searchStringChanged={props.searchStringChanged}
        selectedTags={toMaybe(props.keywords)}
        toggleAddKeywords={props.toggleAddKeywords}
      />
    </div>
  );
};

Content.propTypes = {
  addKeyword: PropTypes.func.isRequired,
  addKeywords: PropTypes.bool.isRequired,
  classifications: PropTypes.array.isRequired,
  classificationsChanged: PropTypes.func.isRequired,
  deleteKeywordClicked: PropTypes.func.isRequired,
  documentTypes: PropTypes.array.isRequired,
  documentTypesChanged: PropTypes.func.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.object).isRequired,
  note: PropTypes.string.isRequired,
  onNoKeyWords: PropTypes.func.isRequired,
  receivedAt: PropTypes.shape(maybeShape).isRequired,
  searchResult: PropTypes.shape(maybeShape).isRequired,
  searchString: PropTypes.string.isRequired,
  searchStringChanged: PropTypes.func.isRequired,
  toggleAddKeywords: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  noteChanged: PropTypes.func.isRequired,
  matchedWords: PropTypes.string,
  isPiiClassifierVisible: PropTypes.bool.isRequired,
  isPiiChecked: PropTypes.bool.isRequired,
  onTogglePiiCheckbox: PropTypes.func.isRequired,
  onSelectPiiSection: PropTypes.func.isRequired,
  userSections: PropTypes.arrayOf(PropTypes.shape(selectOptionShape)).isRequired,
};

export default Content;
