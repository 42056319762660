import { connect } from 'react-redux';
import { toAction } from 'its-js-utility';
import { InsuranceContexts } from 'its-react-ui';
import * as a from './actions';
import { UPDATE_ACTIVE_INDEX } from '../ContextNav/constants';
import { WATCH_GET_COVER_INFO } from '../CoverInfo/constants';
import { UPDATE_ANALYSIS_CONTEXT_LIST } from '../DocumentPage/constants';

const mapStateToProps = ({
  contextList, contextNav, coverInfo, documentPage
}) => ({
  activeContextIndex: contextNav.activeContextIndex, // is used for claims and covers
  contextId: contextList.contextId, // is used for claims only
  showModal: contextList.showModal, // is used for claims only
  initialContextGroups: documentPage.documentAnalysis.data.contextList, // is used for covers to compare search results with analysis results
  coverInfo, // it is used in ContextList component of its-react-ui for archived covers only
  coversInfo: coverInfo, // it is used in ContextGroupList component of its-react-ui for covers only
  selectedContexts: contextList.selectedContexts, // it is used in ContextGroupList component of its-react-ui for covers only :: Maybe [ { id } ]
});

const mapDispatchToProps = dispatch => ({
  closeModal: a.closeModal(dispatch),
  contextClicked: (context, clickedOptions) => dispatch(a.contextListClicked(context, clickedOptions)),
  openModal: a.openModal(dispatch),
  getCoverInfo: coverId => dispatch(toAction(WATCH_GET_COVER_INFO, { coverId })),
  updateAnalysisContextList: maybeNewContextList => {
    dispatch(toAction(UPDATE_ANALYSIS_CONTEXT_LIST, maybeNewContextList));
    dispatch(toAction(UPDATE_ACTIVE_INDEX, maybeNewContextList));
  },
  contextGroupClicked: (context, selected) => a.contextGroupClicked(dispatch, context, selected),
  setSelectedContexts: newSelectedContextList => a.setSelectedContexts(dispatch, newSelectedContextList),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsuranceContexts);
