import { prop } from 'ramda';
import { remoteDataToMaybe } from 'its-js-utility';
import { stringTrimToLowerMatches } from '../../lib';

/* NOTE: Return different default values so they don't match when data
 * has either not arrived yet/failed,  or is missing
*/
export const getOriginallyArchivedBy = remoteDocument =>
  remoteDataToMaybe(remoteDocument)
    .flatMap(prop('originallyArchivedBy'))
    .orJust({ user: { name: 'no originally archived by' } }).user.name;

const getUserName = remoteUser =>
  remoteDataToMaybe(remoteUser)
    .map(prop('name'))
    .orJust('no user name');

const selectUserCanUnarchive = (remoteDocument, remoteUser) => {
  const originallyArchivedBy = getOriginallyArchivedBy(remoteDocument);
  const userName = getUserName(remoteUser, '');

  return stringTrimToLowerMatches(originallyArchivedBy, userName);
};

export default selectUserCanUnarchive;
