import { handleActions } from 'redux-actions';
import { RemoteData } from 'its-js-utility';
import * as a from './constants';

export const defaultState = {
  searchResult: RemoteData.NotAsked,
  searchText: ''
};

const reducer = handleActions({
  [a.SEARCH_TEXT_CHANGED]: (state, action) => ({
    ...state, searchText: action.payload
  }),
  [a.FETCH_SEARCH_RESULTS]: (state, action) => ({
    ...state, searchResult: RemoteData.Loading
  }),

  [a.FETCH_SEARCH_RESULTS_ERROR]: (state, action) => ({
    ...state, searchResult: RemoteData.Failure(action.payload)
  }),

  [a.FETCH_SEARCH_RESULTS_SUCCESS]: (state, action) => ({
    ...state, searchResult: RemoteData.Success(action.payload)
  }),

  [a.CLEAR_SEARCH_STRING]: (state, action) => ({
    ...state,
    searchText: defaultState.searchText
  }),

  [a.RESET_SEARCH]: (state, action) => defaultState

}, defaultState);

export default reducer;
