import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { onChange } from 'its-js-utility';

const TextField = ({ focused, selectAllOnFocus = false, ...props }) => {
  const inputEl = useRef(null);
  useEffect(() => {
    if (focused) {
      inputEl.current.focus();
    }
  }, [focused]);

  return (
    <input ref={inputEl}
           type="text"
           className={props.className}
           placeholder={props.placeholder}
           value={props.value}
           onFocus={e => selectAllOnFocus && e.target.select()}
           onChange={onChange(props.onChange)}
           onKeyDown={props.onKeyDown} />
  );
};

TextField.defaultProps = {
  onKeyDown: null,
  placeholder: '',
  className: '',
  focused: false
};

TextField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  focused: PropTypes.bool,
  selectAllOnFocus: PropTypes.bool,
};

export default TextField;
