import { filter, find } from 'ramda';
import { connect } from 'react-redux';
import { remoteDataToMaybe } from 'its-js-utility';
import { InsuranceContextsNavigator } from 'its-react-ui';
import * as a from './actions';

const selectedContainsOtherContexts = (maybeContextList, maybeSelectedContexts) =>
  maybeContextList.cata(
    () => maybeSelectedContexts.orJust([]).length > 0,
    contexts => {
      const selectedNotInContextList =
        filter(c => !find(con => con.id === c.id, contexts), maybeSelectedContexts.orJust([]));
      return (selectedNotInContextList.length > 0);
    }
  );

const contextsHasBeenAddedOnSearchPage = (documentAnalysis, selectedContexts) =>
  remoteDataToMaybe(documentAnalysis)
    .cata(
      () => false,
      analysis => selectedContainsOtherContexts(analysis.contextList, selectedContexts)
    );

const getContextListIds = documentPage =>
  [...Array(documentPage.documentAnalysis.data.contextList.orJust([]).length).keys()].map(key => key.toString());

const mapStateToProps = ({ contextList, contextNav, documentPage }) => ({
  ...contextNav,
  resetIndex: contextsHasBeenAddedOnSearchPage(documentPage.documentAnalysis, contextList.selectedContexts),
  selectedContexts: contextList.selectedContexts,
  activeIndex: contextNav.activeContextIndex,
  selectableIds: getContextListIds(documentPage),
  selectedIds: getContextListIds(documentPage)
});

const mapDispatchToProps = dispatch => ({
  activeContextChanged: a.activeContextChanged(dispatch),
  resetActiveIndex: a.resetActiveIndex(dispatch)
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  activeContextChanged: dispatchProps.activeContextChanged(ownProps.isGrouped ? stateProps.selectableIds : ownProps.contextList)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(InsuranceContextsNavigator);
