import { handleActions } from 'redux-actions';
import { RemoteData, remoteDataToMaybe } from 'its-js-utility';
import * as a from './constants';

const archivedPageState = {
  document: RemoteData.NotAsked,
  hasArchived: false,
  shouldRedirect: true,
};

const getHasArchived = (remoteDocument, remoteHasArchived) =>
  remoteDataToMaybe(remoteHasArchived)
    .cata(
      () => remoteDocument.isSuccess(),
      _ => true,
    );

const reducer = handleActions({
  [a.FETCH_DOCUMENT]: state => ({
    ...state,
    document: RemoteData.Loading,
  }),

  [a.FETCH_DOCUMENT_ERROR]: (state, action) => ({
    ...state,
    document: RemoteData.Failure(action.payload),
  }),

  [a.FETCH_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    document: RemoteData.Success(action.payload),
    hasArchived: true,
    shouldRedirect: true,
  }),

  [a.SET_HAS_ARCHIVED]: (state, action) => {
    const hasArchived = getHasArchived(state.document, action.payload);
    return {
      ...state,
      hasArchived,
    };
  },

  [a.SET_SHOULD_REDIRECT]: (state, action) => ({
    ...state,
    shouldRedirect: action.payload,
  }),
}, archivedPageState);

export default reducer;
