import { connectWithInit, remoteDataToMaybe } from 'its-js-utility';
import fetchIsArchivedSagaWatchAction from './actions';
import {
  fetchUserSagaWatchAction,
  fetchRolesSagaWatchAction,
} from '../User/actions';
import DocumentStatusPage from '../../pages/DocumentStatusPage';

const remoteInitial = (documentStatus, remoteRoles) =>
  remoteRoles
    .flatMap(_ => documentStatus);

const mapStateToProps = ({ documentStatus: { documentStatus }, user }) => ({
  initialData: remoteInitial(documentStatus, user.remoteRoles),
  user,
});

export const mapDispatchToProps = dispatch => ({
  dispatch
});

const mergeProps = (sProps, dProps, ownProps) => ({
  ...sProps,
  ...dProps,
  ...ownProps,
  fetchInitialData: (shouldFetchRoles, shouldFetchUser) => {
    if (shouldFetchUser) {
      dProps.dispatch(fetchUserSagaWatchAction());
    }
    if (shouldFetchRoles) {
      dProps.dispatch(fetchRolesSagaWatchAction());
    }
  },
  fetchIsArchived: u => {
    remoteDataToMaybe(u).cata(
      () => null,
      user => dProps.dispatch(fetchIsArchivedSagaWatchAction(user.mailbox))
    );
  }
});

const init = props => {
  const { remoteRoles, user } = props.user;
  if (!user.isSuccess()) {
    props.dispatch(fetchUserSagaWatchAction());
  }
  if (!remoteRoles.isSuccess()) {
    props.dispatch(fetchRolesSagaWatchAction());
  }
};

export default connectWithInit(
  init,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DocumentStatusPage);
