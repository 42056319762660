import { RemoteData, sortDesc } from 'its-js-utility';
import filterBySelectedKeywords from './filterBySelectedKeywords';
import mapSortDocumentTypes from './mapSortDocumentTypes';
import updateClassifications from './updateClassifications';
import searchKeywords from './searchKeywords';
import validateDocument from '../validation';

const defaultDocType = {
  displayName: '',
  value: '',
  disabled: false
};

const getUpdatedDocumentTypes = (selectedDocType, documentTypes) => {
  const updatedDocumentTypes = mapSortDocumentTypes(selectedDocType.value, documentTypes);
  return updatedDocumentTypes;
};

const mapAnalysisState = (
  state,
  documentAnalysis,
  keywords,
  classifications,
  documentTypes,
  messageId
) => {
  const taxonomy = documentAnalysis.taxonomy.orJust(null);
  const selectedDocType = taxonomy && documentTypes.find(docType => docType.value === taxonomy.documentType.value) ? taxonomy.documentType : defaultDocType;

  const updatedDocumentTypes =
    getUpdatedDocumentTypes(selectedDocType, documentTypes);

  const selectedKeywords = documentAnalysis.taxonomy
    .flatMap(({ documentTags, _ }) =>
      filterBySelectedKeywords(documentTags, selectedDocType, keywords))
    .map(sortDesc('displayName'));

  const documentModel = {
    ...state.documentModel,
    documentType: selectedDocType.disabled ? '' : selectedDocType.value,
    keywords: selectedKeywords.orJust([]),
    classification: documentAnalysis.classification.orJust(state.documentModel.classification),
    note: documentAnalysis.note || ''
  };

  const sortedKeywords = sortDesc('displayName', keywords);
  return {
    ...state,
    documentModel,
    classifications: updateClassifications(classifications, documentAnalysis.classification),
    documentTypes: updatedDocumentTypes,
    keywords: sortedKeywords,
    documentAnalysis: RemoteData.Success(documentAnalysis),
    searchResult: searchKeywords(sortedKeywords, selectedDocType, ''),
    validation: validateDocument(documentModel, state.validation.showValidationResult),
    messageId,
  };
};

export default mapAnalysisState;
