import { takeLatest, call, put } from 'redux-saga/effects';
import { toAction } from 'its-js-utility';
import { includes } from 'ramda';
import { get } from '../../apis/';
import getUser from '../../apis/user';
import * as a from './constants';
import { mapRoles } from '../../apis/mappers';

export const getRoles = () =>
  get('/api/DocumentTypes/roles');

export function* userFetchUserWorkerSaga() {
  try {
    yield put(toAction(a.FETCH_USER));
    const user = yield call(getUser);
    yield put(toAction(a.FETCH_USER_SUCCESS, user));
  } catch (err) {
    if (includes('MISSING MAILBOX', err.message)) {
      yield call(window.sessionStorage.clear);
      yield call(window.localStorage.clear);
      yield call(window.location.reload);
    }
    yield put(toAction(a.FETCH_USER_ERROR, err));
  }
}

export function* userFetchRolesWorkerSaga() {
  try {
    yield put(toAction(a.FETCH_ROLES));
    const roles = yield call(getRoles);
    const mappedRoles = yield call(mapRoles, roles);
    yield put(toAction(a.FETCH_ROLES_SUCCESS, mappedRoles));
  } catch (err) {
    yield put(toAction(a.FETCH_ROLES_ERROR, err));
  }
}

export function* userFetchUserWatcherSaga() {
  yield takeLatest(a.USER_WATCH_FETCH_USER, userFetchUserWorkerSaga);
}

export function* userFetchRolesWatcherSaga() {
  yield takeLatest(a.USER_WATCH_FETCH_ROLES, userFetchRolesWorkerSaga);
}
