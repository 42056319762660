import { Nothing } from 'monet';
import { handleActions } from 'redux-actions';
import {
  filter, uniqBy, pipe,
} from 'ramda';
import {
  RemoteData, sortDesc,
} from 'its-js-utility';
import * as a from './constants';
import {
  filterBySelectedKeywords,
  searchKeywords,
  mapAnalysisState,
  getArchivedDocumentAnalysis,
  updateSelected,
  getSelectedDocType,
  updateDocumentTypes,
} from './helpers';
import validateDocument from './validation';

const defaultDocument = {
  applicationRoleName: null,
  classification: '',
  contexts: [],
  documentType: '',
  keywords: [],
  note: '',
  suggestedKeywords: RemoteData.NotAsked,
  piiSection: null,
};

export const defaultState = {
  activeContextIndex: 0,
  addKeywords: false,
  classifications: [],
  documentAnalysis: RemoteData.NotAsked,
  documentModel: defaultDocument,
  documentTypes: [],
  hasArchived: RemoteData.NotAsked,
  invalidAttachments: [],
  isPiiChecked: false,
  keywords: [],
  messageId: undefined,
  roleClassifications: {},
  roleDocumentTypes: {},
  searchResult: Nothing(),
  searchString: '',
  validation: validateDocument(defaultDocument, false),
  verticalListing: false,
};

const addUniq = (keywords, docType, payload) =>
  pipe(
    uniqBy(({ value }) => value),
    filter(keyword => keyword.docType === docType),
  )([...keywords, payload]);

const reducer = handleActions({
  [a.CLASSIFICATIONS_CHANGED]: (state, action) => {
    const documentModel = {
      ...state.documentModel,
      classification: action.payload.value,
    };

    return {
      ...state,
      classifications: updateSelected(action.payload, state.classifications),
      documentModel,
      validation: validateDocument(documentModel, state.validation.showValidationResult),
    };
  },

  [a.FETCH_ANALYSIS]: state => ({
    ...state,
    documentAnalysis: RemoteData.Loading,
  }),

  [a.FETCH_ANALYSIS_ERROR]: (state, action) => ({
    ...state,
    documentAnalysis: RemoteData.Failure(action.payload),
  }),

  [a.FETCH_ANALYSIS_SUCCESS]: (state, action) => {
    const {
      documentAnalysis, classifications, documentTypes, keywords, messageId,
    } = action.payload;
    return mapAnalysisState(state, documentAnalysis, keywords, classifications, documentTypes, messageId,);
  },

  [a.FETCH_CLASSIFICATIONS_SUCCESS]: (state, action) => {
    const { classifications, role } = action.payload;
    return {
      ...state,
      roleClassifications: {
        ...state.roleClassifications,
        [role]: classifications,
      }
    };
  },

  [a.FETCH_DOCUMENT_TYPES_SUCCESS]: (state, action) => {
    const { documentTypes, role } = action.payload;
    return {
      ...state,
      roleDocumentTypes: {
        ...state.roleDocumentTypes,
        [role]: documentTypes,
      }
    };
  },

  [a.FETCH_EDIT_DOCUMENT]: state => ({
    ...state,
    documentAnalysis: RemoteData.Loading,
  }),

  [a.FETCH_EDIT_DOCUMENT_ERROR]: (state, action) => ({
    ...state,
    documentAnalysis: RemoteData.Failure(action.payload),
  }),

  [a.FETCH_EDIT_DOCUMENT_SUCCESS]: (state, action) => {
    const {
      archivedDocument, classifications, documentTypes, keywords, messageId,
    } = action.payload;
    const documentAnalysis = getArchivedDocumentAnalysis(archivedDocument);

    return mapAnalysisState(state, documentAnalysis, keywords, classifications, documentTypes, messageId);
  },

  [a.FETCH_UPDATE_TAXONOMY_SUCCESS]: (state, action) => {
    const keywords = filterBySelectedKeywords(
      action.payload.orJust([]),
      getSelectedDocType(state.documentTypes),
      state.keywords,
    );

    const documentModel = {
      ...state.documentModel,
      keywords: keywords.orJust([]),
    };

    return {
      ...state,
      documentModel,
      validation: validateDocument(documentModel, state.validation.showValidationResult),
    };
  },

  [a.ARCHIVE_DOCUMENT]: state => ({
    ...state,
    hasArchived: RemoteData.Loading,
  }),

  [a.ARCHIVE_DOCUMENT_ERROR]: (state, action) => ({
    ...state,
    hasArchived: RemoteData.Failure(action.payload),
  }),

  [a.ARCHIVE_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    hasArchived: RemoteData.Success(action.payload),
  }),

  [a.DOCUMENT_TYPES_CHANGED]: (state, action) => {
    const documentTypes = updateDocumentTypes(state, action.payload);
    const selectedDocumentType = getSelectedDocType(documentTypes);
    const searchResult = searchKeywords(state.keywords, selectedDocumentType, state.searchString);
    const documentModel = {
      ...state.documentModel,
      documentType: action.payload.value,
      keywords: [],
    };

    return {
      ...state,
      searchResult,
      documentTypes,
      documentModel,
      validation: validateDocument(documentModel, state.validation.showValidationResult),
    };
  },

  [a.DELETE_KEYWORD]: (state, action) => ({
    ...state,
    documentModel: {
      ...state.documentModel,
      keywords: filter(tag => tag.value !== action.payload.value, state.documentModel.keywords),
    },
  }),

  [a.ADD_KEYWORD]: (state, action) => {
    const { documentModel } = state;
    const keywords = addUniq(documentModel.keywords, documentModel.documentType, action.payload);

    const newDocumentModel = {
      ...state.documentModel,
      keywords: sortDesc('displayName', keywords),
    };

    return {
      ...state,
      documentModel: newDocumentModel,
      validation: validateDocument(documentModel, state.validation.showValidationResult),
    };
  },

  [a.TOGGLE_ADD_KEYWORDS]: (state, action) => ({
    ...state,
    addKeywords: action.payload || !state.addKeywords,
    searchString: '',
    searchResult: state.addKeywords
      ? searchKeywords(state.keywords, getSelectedDocType(state.documentTypes), '')
      : state.searchResult,
  }),

  [a.SEARCH_STRING_CHANGED]: (state, action) => ({
    ...state,
    searchString: action.payload,
    searchResult: searchKeywords(
      state.keywords,
      getSelectedDocType(state.documentTypes),
      action.payload,
    ),
  }),

  [a.VALIDATE_ATTACHMENTS]: state => ({
    ...state,
  }),

  [a.SHOW_VALIDATION_RESULT]: state => ({
    ...state,
    validation: {
      ...state.validation,
      showValidationResult: true,
    },
  }),

  [a.SHOW_INVALID_ATTACHMENTS_RESULT]: (state, action) => ({
    ...state,
    invalidAttachments: action.payload,
  }),

  [a.ARCHIVE_DOCUMENT_CANCEL]: state => ({
    ...state,
    invalidAttachments: [],
  }),

  [a.ARCHIVE_DOCUMENT_EXCLUDING_INVALID_ATTACHMENTS]: state => ({
    ...state,
    invalidAttachments: [],
  }),

  [a.UPDATE_DOCUMENT]: state => ({
    ...state,
    hasArchived: RemoteData.Loading,
  }),

  [a.UPDATE_DOCUMENT_ERROR]: (state, action) => ({
    ...state,
    hasArchived: RemoteData.Failure(action.payload),
  }),

  [a.UPDATE_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    hasArchived: RemoteData.Success(action.payload),
  }),

  [a.UPDATE_ANALYSIS_CONTEXT_LIST]: (state, action) => ({
    ...state,
    documentAnalysis: RemoteData.Success({
      ...state.documentAnalysis.data,
      contextList: action.payload,
    }),
  }),

  [a.TOGGLE_CONTEXT_LISTING]: state => ({
    ...state,
    verticalListing: !state.verticalListing,
  }),

  [a.RESET_DOCUMENT_PAGE]: state => ({
    ...defaultState,
    roleClassifications: state.roleClassifications,
    roleDocumentTypes: state.roleDocumentTypes,
  }),

  [a.ADD_NOTE]: (state, action) => ({
    ...state,
    documentModel: {
      ...state.documentModel,
      note: action.payload,
    },
  }),

  [a.FETCH_SUGGESTIONS]: state => ({
    ...state,
    documentModel: {
      ...state.documentModel,
      suggestedKeywords: RemoteData.Loading,
    },
  }),

  [a.FETCH_SUGGESTIONS_ERROR]: (state, action) => ({
    ...state,
    documentModel: {
      ...state.documentModel,
      suggestedKeywords: RemoteData.Failure(action.payload),
    },
  }),

  [a.FETCH_SUGGESTIONS_SUCCESS]: (state, action) => ({
    ...state,
    documentModel: {
      ...state.documentModel,
      suggestedKeywords: RemoteData.Success(action.payload),
    },
  }),

  [a.IS_PII_CHECKED_CHANGED]: (state, action) => ({
    ...state,
    isPiiChecked: action.payload
  }),

  [a.PII_SECTION_CHANGED]: (state, action) => ({
    ...state,
    documentModel: {
      ...state.documentModel,
      piiSection: action.payload,
    },
  }),

}, defaultState);

export default reducer;
