import { maybeProp, toMaybe } from 'its-js-utility';
import { dateToMaybe, generateUniqueIdsForReinsuranceContexts, isReinsuranceContextsList } from '../../lib';
import mapClassification from './mapClassification';
import mapMaybeTaxonomy from './mapMaybeTaxonomy';
import mapMaybeValidatedContexts, { mapMaybeInsuranceContextGroups } from './mapMaybeValidatedContexts';

const mapReceivedAt = analysis =>
  maybeProp('receivedAt', analysis)
    .map(receivedAt => new Date(receivedAt))
    .flatMap(dateToMaybe);

const mapDocumentTypeMatchedWords = analysis => {
  if (!analysis.analysisMetadata) {
    return '';
  }
  const { analysisMetadata: { taxonomyDocumentTypeMatchedWords } } = analysis;
  return taxonomyDocumentTypeMatchedWords ? taxonomyDocumentTypeMatchedWords.join() : '';
};

const mapContextList = analysis => {
  const maybeContexts = mapMaybeValidatedContexts(analysis);
  const contexts = maybeContexts.orJust([]);
  return isReinsuranceContextsList(contexts) ? toMaybe(generateUniqueIdsForReinsuranceContexts(contexts, true, true)) : maybeContexts;
};

const mapDocumentAnalysis = analysis => ({
  classification: mapClassification(analysis),
  contextList: mapContextList(analysis),
  isGrouped: false,
  matchedWords: mapDocumentTypeMatchedWords(analysis),
  receivedAt: mapReceivedAt(analysis),
  taxonomy: mapMaybeTaxonomy(analysis),
});

export const mapDocumentGroupedAnalysis = groupAnalysis => ({
  classification: mapClassification(groupAnalysis),
  contextList: mapMaybeInsuranceContextGroups(groupAnalysis),
  isGrouped: true,
  matchedWords: mapDocumentTypeMatchedWords(groupAnalysis),
  receivedAt: mapReceivedAt(groupAnalysis),
  taxonomy: mapMaybeTaxonomy(groupAnalysis),
  note: groupAnalysis.notes
});

export default mapDocumentAnalysis;
