import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toAction } from 'its-js-utility';
import { Modal, ModalMessage } from 'its-react-ui';
import { withRouter } from 'react-router';
import Router from './Router';
import { CLOSE_MODAL_MESSAGE } from '../containers/App/constants';
import { runPostModalActions } from '../containers/App/ModalActions';
import useVerifyAppVersion from '../lib/useVerifyAppVersion';

const ModalMessageWithRouter = ({ modalMessage, history }) => {
  const dispatch = useDispatch();
  const closeModal = () => Promise.resolve(dispatch(toAction(CLOSE_MODAL_MESSAGE))).then(() =>
    runPostModalActions(modalMessage.postCloseAction, modalMessage.postCloseArgs, history));

  return (
    <ModalMessage
      header={modalMessage.header}
      content={modalMessage.content}
      onClose={() => closeModal()}
    />
  );
};

ModalMessageWithRouter.propTypes = {
  modalMessage: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const Message = withRouter(ModalMessageWithRouter);

const App = () => {
  const { modalMessage } = useSelector(state => state.app);
  useVerifyAppVersion();

  return (
    <Router>
      {modalMessage.show === true &&
        (
          <Modal show={modalMessage.show}>
            <Message modalMessage={modalMessage} />
          </Modal>
        )}
    </Router>
  );
};

export default App;
