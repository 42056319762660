import {
  take, takeLatest, takeEvery, call, put, select, race,
} from 'redux-saga/effects';
import {
  maybeProp, remoteDataToMaybe, toAction,
} from 'its-js-utility';
import {
  post, jsonOrThrow, put as apiPut,
  addItemCategoryToItem, fetchGroupAnalyse,
  getUpdateTaxonomy, fetchAnalysis, hasInsifyCategory,
  getMappedClassifications, getMappedDocumentTypes,
} from '../../apis/';
import * as contextNavActions from '../ContextNav/constants';
import * as a from './constants';
import validateDocument from './validation';
import { getDefaultDocumentType } from './helpers';
import {
  mapArchiveDocument,
  mapUpdateDocument,
} from '../../apis/mappers';
import { SET_SHOULD_REDIRECT } from '../ArchivedPage/constants';
import { getAadToken } from '../../appAuth/msalAuth';
import * as office from '../../apis/office';
import { isUnderwriter } from '../../lib';

export const getErrorMessage = err => {
  switch (err.status) {
    case 406:
      return 'Failed to archive document. One or more attachments were of invalid file type';
    case 409:
      return 'Document already archived';
    default:
      return 'Failed to archive document. Please try again.';
  }
};

export const checkForInvalidAttachments = selectedData =>
  post('/api/email/check-for-invalid-attachments', {}, selectedData)
    .then(jsonOrThrow);

export const archiveDocument = selectedData =>
  post('/api/email/create', {}, selectedData);

export const shouldRedirect = state => {
  const { archivedPage: { shouldRedirect: result } } = state;
  return result;
};

// It is exported to be used in tests only
export function* archiveDocumentWorkerSaga(args) {
  yield put(toAction(a.ARCHIVE_DOCUMENT));
  const {
    skipInvalidFileTypes, docModel, messageId, mailbox, aadToken, item,
  } = args;
  const validation = yield call(validateDocument, docModel);
  const { valid } = validation;
  if (valid) {
    const selectedData =
      yield call(
        mapArchiveDocument,
        aadToken,
        messageId,
        mailbox,
        docModel,
        skipInvalidFileTypes || false
      );
    const result = yield call(archiveDocument, selectedData.right());
    try {
      // <LEAPDEV-1180> is a disabled feature, thus addItemCategoryToItem will always resolve true
      yield call(addItemCategoryToItem, item);
    } catch (e) {
      // AddCategory will fail if the user has not yet archived an e-mail
      // after implementation of category marking of e-mails.
      // The reason is that the client do not have permission to add categories to
      // the users master categories, thus it will not exist until a document is archived
      // and the master categories ar updated on the server.
    }

    const redirect = yield select(shouldRedirect);
    if (redirect) {
      yield put(toAction(a.ARCHIVE_DOCUMENT_SUCCESS, result));
    }
  } else {
    yield put(toAction(a.SHOW_VALIDATION_RESULT));
  }
}

// This function validate attachments and archives the document afterwards
export function* validateAttachmentsWorkerSaga(args) {
  yield put(toAction(a.VALIDATE_ATTACHMENTS));
  yield put(toAction(SET_SHOULD_REDIRECT, true));

  const {
    payload: {
      mailbox, messageId, docModel, aadToken,
    },
  } = args;

  try {
    const selectedData =
      yield call(mapArchiveDocument, aadToken, messageId, mailbox, docModel, false);
    const invalidAttachments = yield call(checkForInvalidAttachments, selectedData.right());

    if (invalidAttachments.length === 0) {
      yield call(archiveDocumentWorkerSaga, { ...args.payload });
    } else {
      yield put(toAction(a.SHOW_INVALID_ATTACHMENTS_RESULT, invalidAttachments));
      const { archive } = yield race({
        archive: take(a.ARCHIVE_DOCUMENT_EXCLUDING_INVALID_ATTACHMENTS),
        cancel: take(a.ARCHIVE_DOCUMENT_CANCEL),
      });

      if (archive) {
        yield call(archiveDocumentWorkerSaga, {
          ...args.payload,
          skipInvalidFileTypes: true,
        });
      }
    }
  } catch (err) {
    const errorPayload = {
      status: err.status,
      message: getErrorMessage(err),
    };
    yield put(toAction(a.ARCHIVE_DOCUMENT_ERROR, errorPayload));
  }
}

export const unlockFailedMessage = 'Failed to unlock document. Please try again.';

export const getCachedData = (store, role) => ({
  mappedClassifications: store.documentPage.roleClassifications[role],
  mappedDocumentTypes: store.documentPage.roleDocumentTypes[role],
});

export function* getClassificationsAndDocumentTypesWorkerSaga(args) {
  const { role } = args.payload;
  let { mappedClassifications, mappedDocumentTypes } = yield select(getCachedData, role);

  if (!mappedClassifications) {
    // parameter role in the next statement has no impact as the endpoint in getMappedClassifications
    // does not consider role yet. Classifications are still cached per role
    mappedClassifications = yield call(getMappedClassifications, role);
    yield put(toAction(
      a.FETCH_CLASSIFICATIONS_SUCCESS,
      {
        classifications: mappedClassifications,
        role,
      }
    ));
  }
  if (!mappedDocumentTypes) {
    mappedDocumentTypes = yield call(getMappedDocumentTypes, role);
    yield put(toAction(
      a.FETCH_DOCUMENT_TYPES_SUCCESS,
      {
        documentTypes: mappedDocumentTypes,
        role,
      }
    ));
  }

  return {
    classifications: mappedClassifications,
    documentTypes: mappedDocumentTypes,
  };
}

export function* fetchEditDocumentWorkerSaga(args) {
  yield put(toAction(a.FETCH_EDIT_DOCUMENT));
  yield put(toAction(SET_SHOULD_REDIRECT, false));
  try {
    const {
      payload: {
        role,
        archivedDocument,
        applicationRoleName,
        messageId,
      },
    } = args;
    const { classifications, documentTypes } =
      yield call(getClassificationsAndDocumentTypesWorkerSaga, {
        payload: {
          role: (applicationRoleName && applicationRoleName.value) || role.value,
        },
      });
    const document = {
      archivedDocument,
      classifications,
      documentTypes: documentTypes.documentTypes,
      keywords: documentTypes.tags,
    };
    yield put(toAction(a.FETCH_EDIT_DOCUMENT_SUCCESS, {
      ...document,
      messageId,
    }));
    yield put(toAction(SET_SHOULD_REDIRECT, true));
  } catch (err) {
    yield put(toAction(a.FETCH_EDIT_DOCUMENT_ERROR, unlockFailedMessage));
  }
}

export const getArchivedDocumentId = document =>
  remoteDataToMaybe(document)
    .flatMap(maybeProp('id'))
    .orJust(null);

export const updateDocument = updateDocumentReq =>
  apiPut('/api/document', {}, updateDocumentReq);

export function* updateDocumentWorkerSaga(args) {
  yield put(toAction(a.UPDATE_DOCUMENT));
  try {
    const {
      payload: {
        mailBox,
        messageId,
        aadToken,
        docModel,
        archivedPage,
        item,
      },
    } = args;
    const { valid } = yield call(validateDocument, docModel);
    if (valid) {
      const docId = yield call(getArchivedDocumentId, archivedPage.document);
      try {
        const updateDocumentReq =
          yield call(mapUpdateDocument, docId, docModel);
        const updateDocumentRequest = {
          ...updateDocumentReq.right(),
          mailBox,
          token: aadToken,
          messageId,
        };
        const result = yield call(updateDocument, updateDocumentRequest);
        const itemHasInsifyCategory = yield call(hasInsifyCategory, item);
        if (!itemHasInsifyCategory) {
          yield call(addItemCategoryToItem, item);
        }
        yield put(toAction(a.UPDATE_DOCUMENT_SUCCESS, result));
      } catch (err) {
        const errorPayload = {
          status: err.status,
          message: getErrorMessage(err),
        };
        yield put(toAction(a.UPDATE_DOCUMENT_ERROR, errorPayload));
      }
    } else {
      yield put(toAction(a.SHOW_VALIDATION_RESULT));
    }
  } catch (err) {
    yield put(toAction(a.UPDATE_DOCUMENT_ERROR));
  }
}

export function* updateTaxonomyWorkerSaga(args) {
  const { user, documentType } = args.payload;
  let { aadToken, emailId } = args.payload;
  if (!aadToken) {
    aadToken = yield call(getAadToken);
  }

  if (!emailId) {
    emailId = yield call(office.getRestifiedItemId, window.Office);
  }

  yield put(toAction(a.DOCUMENT_TYPES_CHANGED, documentType));
  try {
    const taxonomy = yield call(
      getUpdateTaxonomy,
      emailId,
      aadToken,
      user.mailbox,
      user.role.value,
      documentType.displayName,
    );
    yield put(toAction(a.FETCH_UPDATE_TAXONOMY_SUCCESS, taxonomy));
    yield put(toAction(a.SEARCH_STRING_CHANGED, ''));
  } catch (_) {
    yield put(toAction(a.FETCH_UPDATE_TAXONOMY_ERROR));
  }
}

export function* getAnalysisWorkerSaga(args) {
  yield put(toAction(a.FETCH_ANALYSIS));
  const aadToken = yield call(getAadToken);
  const emailId = yield call(office.getRestifiedItemId, window.Office);
  const { user, messageId } = args.payload;
  const { mailbox, role } = user;

  try {
    const { classifications, documentTypes } =
      yield call(getClassificationsAndDocumentTypesWorkerSaga, {
        payload: {
          role: role.value,
        },
      });

    const docAnalysis = {
      documentAnalysis:
        isUnderwriter(role.value)
          ? yield call(fetchGroupAnalyse, emailId, aadToken, mailbox, role.value)
          : yield call(fetchAnalysis, emailId, aadToken, mailbox, role.value),
      classifications,
      documentTypes: documentTypes.documentTypes,
      keywords: documentTypes.tags,
    };

    yield put(toAction(
      a.FETCH_ANALYSIS_SUCCESS,
      isUnderwriter(role.value)
        ? {
          ...docAnalysis,
          messageId,
        }
        : docAnalysis
    ));
    yield put(toAction(contextNavActions.RESET_CONTEXT_NAV));

    if (docAnalysis.documentAnalysis.taxonomy.isNothing()) {
      const defaultDocumentType = getDefaultDocumentType(role.value);
      yield call(
        updateTaxonomyWorkerSaga,
        {
          payload: {
            user,
            aadToken,
            emailId,
            documentType: defaultDocumentType,
          },
        }
      );
    }
  } catch (_) {
    yield put(toAction(
      a.FETCH_ANALYSIS_ERROR,
      `Failed to analyse email as ${role.value}. Please dismiss this message and try again.`
    ));
  }
}

export function* fetchEditDocumentWatchSaga() {
  yield takeLatest(a.WATCH_FETCH_EDIT_DOCUMENT, fetchEditDocumentWorkerSaga);
}

export function* getAnalysisWatchSaga() {
  yield takeLatest(a.WATCH_ANALYSE_DOCUMENT, getAnalysisWorkerSaga);
}

export function* updateDocumentWatchSaga() {
  yield takeLatest(a.WATCH_UPDATE_DOCUMENT, updateDocumentWorkerSaga);
}

export function* updateTaxonomyWatchSaga() {
  yield takeLatest(a.WATCH_UPDATE_TAXONOMY, updateTaxonomyWorkerSaga);
}

export function* validateAttachmentsWatchSaga() {
  yield takeEvery(a.WATCH_VALIDATE_ATTACHMENTS, validateAttachmentsWorkerSaga);
}
