import { mapTaxonomyDisplayName } from 'its-js-utility';
import { map } from 'ramda';

export const mapDisplayNameValue = tag => ({
  displayName: mapTaxonomyDisplayName(tag),
  value: tag
});

const mapTags = documentTags =>
  map(mapDisplayNameValue, documentTags || []);

export default mapTags;
