import { handleActions } from 'redux-actions';
import { RemoteData, DocumentState } from 'its-js-utility';
import * as a from './constants';

const documentStatusState = {
  documentStatus: RemoteData.NotAsked
};

const reducer = handleActions({
  [a.FETCH_DOCUMENT_ARCHIVED]: state => ({
    ...state, documentStatus: RemoteData.Loading
  }),

  [a.FETCH_DOCUMENT_ARCHIVED_ERROR]: (state, action) => ({
    ...state, documentStatus: RemoteData.Failure(action.payload)
  }),

  [a.FETCH_DOCUMENT_ARCHIVED_SUCCESS]: (state, action) => {
    const documentState =
      action.payload
        ? DocumentState.Archived
        : DocumentState.NotArchived;

    return { ...state, documentStatus: RemoteData.Success(documentState) };
  }
}, documentStatusState);

export default reducer;
