import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toAction } from 'its-js-utility';
import { useIsAuthenticated } from '@azure/msal-react';
import { SET_APP_VERSION as setVersionAction } from '../containers/App/constants';
import getVersion from '../apis/version';
import getMetaValue from '../lib/getMetaValue';

interface ReduxState {
  app: {
    appVersion: string;
  };
}
const useVerifyAppVersion = () => {
  const { appVersion } = useSelector((state: ReduxState) => state.app);
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      getVersion()
        .then(res => dispatch(
          toAction(
            setVersionAction,
            { appVersion: res.version }
          ),
        ));
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (appVersion !== getMetaValue(document, 'version')) {
      window.location.reload();
    }
  }, [appVersion]);
};

export default useVerifyAppVersion;
