import { dispatchFetchError, dispatchFetchSuccess, toAction } from 'its-js-utility';
import * as actions from './constants';
// eslint-disable-next-line object-curly-newline
import { deleteDocument,
  // removeItemCategoryFromItem, // <LEAPDEV-1180> remove eslint disable
} from '../../apis';

export const unarchiveDocument = ({
  dispatch,
  documentId,
  mailBox,
  messageId,
  aadToken,
  // item, // <LEAPDEV-1180>
}) => {
  dispatch(toAction(actions.UNARCHIVE_DOCUMENT));

  const deleteDocumentRequest = {
    token: aadToken,
    mailBox,
    messageId,
    documentId,
  };

  return deleteDocument(deleteDocumentRequest)
    .then(dispatchFetchSuccess(dispatch, actions.UNARCHIVE_DOCUMENT_SUCCESS))
    // <LEAPDEV-1180>
    /*
    .then(deleteResult => {
      removeItemCategoryFromItem(item) // An error may occur first time category is added
        .finally(() => deleteResult);
    })
    */
    .catch(dispatchFetchError(dispatch, actions.UNARCHIVE_DOCUMENT_ERROR));
};

export const cancelAfterFailure = dispatch => {
  dispatch(toAction(actions.UNARCHIVE_RESET));
};
