import { curry } from 'ramda';
import { Direction, toAction } from 'its-js-utility';
import * as a from './constants';
import { WATCH_GET_COVER_INFO } from '../CoverInfo/constants';
import { resetActiveIndex, activeContextChanged } from '../ContextNav/actions';

export const contextListClicked = (clickedContext, clickedOptions) => dispatch => {
  const isSelected = clickedContext.selected;

  dispatch(toAction(a.CONTEXT_CLICKED, { isSelected, clickedContext, clickedOptions }));

  // If user selects one more year do not change navigation
  if (isSelected && clickedContext.$type === 'ReinsuranceContext' && clickedContext.yearOptions && clickedContext.yearOptions.filter(x => x.selected).length > 0) return;

  if (isSelected) {
    activeContextChanged(dispatch, [], Direction.Prev);
  } else {
    resetActiveIndex(dispatch)();
  }
};

export const contextGroupClicked = curry((dispatch, clickedContext, isSelected) =>
  dispatch(toAction(a.CONTEXT_CLICKED, { isSelected, clickedContext })));

export const setSelectedContexts = (dispatch, contextList) =>
  dispatch(toAction(a.SET_SELECTED_CONTEXTS, contextList));

export const resetContextList = dispatch =>
  () => dispatch(toAction(a.RESET_CONTEXT_LIST));

export const closeModal = dispatch => () => dispatch(toAction(a.CLOSE_MODAL));

export const openModal = curry((dispatch, { id, contextType }) => {
  contextType.cata({
    ClaimContext: () => dispatch(toAction(a.OPEN_MODAL, id)),
    CoverContext: () => {
      dispatch(toAction(WATCH_GET_COVER_INFO, { coverId: id }));
      dispatch(toAction(a.OPEN_MODAL, id));
    },
    ReinsuranceContext: () => {},
    SomeOtherContext: () => {}
  });
});
