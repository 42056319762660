import { Nothing } from 'monet';
import { remoteDataToMaybe } from 'its-js-utility';

const getReceivedAt = remoteAnalysis => {
  if (remoteAnalysis.isSuccess()) {
    return remoteDataToMaybe(remoteAnalysis).flatMap(analysis => analysis.receivedAt);
  }
  return Nothing();
};

export default getReceivedAt;
