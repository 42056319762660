import React from 'react';
import { toAction } from 'its-js-utility';
import { OPEN_MODAL_MESSAGE } from './constants';

export const postModalActions = {
  documentPageNoKeyword: 'DOCUMENT_NO_KEYWORD',
  messageAndRedirect: 'DOCUMENT_EXISTS'
};

export const runPostModalActions = (postCloseAction, args, history) => {
  switch (postCloseAction) {
    case postModalActions.documentPageNoKeyword:
      return null;
    case postModalActions.messageAndRedirect:
      return history.push(args.path);
    default:
      return null;
  }
};

export const showIsNoKeywordsModal = (dispatch, selectedDocType) => {
  const content = (
    <>
      {'There are no keywords for the document type '}
      <em>{`${selectedDocType}.`}</em>
    </>
  );
  dispatch(toAction(
    OPEN_MODAL_MESSAGE,
    {
      header: 'No keywords available',
      content,
      postCloseAction: postModalActions.documentPageNoKeyword,
      postCloseArgs: { message: 'good things' },
    },
  ));
};

export const showCannotUnarchiveNotOwnerModal = (dispatch, originallyArchivedByName) => {
  const content = (
    <>
      <div>{`This document was originally archived by ${originallyArchivedByName}.`}</div>
      <div>Only this user can unarchive the document.</div>
    </>
  );
  dispatch(toAction(
    OPEN_MODAL_MESSAGE,
    {
      header: 'Not allowed to unarchive',
      content,
      postCloseAction: undefined,
      postCloseArgs: undefined,
    },
  ));
};

export const ShowMessageAndRedirect = (dispatch, path) => {
  dispatch(toAction(
    OPEN_MODAL_MESSAGE,
    {
      header: 'Document has already been archived',
      content: 'The document has been archived in another client after it was opened in this client.',
      postCloseAction: postModalActions.messageAndRedirect,
      postCloseArgs: { path },
    },
  ));
};
