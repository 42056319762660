import React from 'react';
import cn from 'classnames';
import { toMaybe } from 'its-js-utility';
import PropTypes from 'prop-types';
import { mdiFileDocumentBox } from '@mdi/js';
import { Icon } from '../../../ui';
import { Maybe } from '../../index';
import styles from './NoteRow.module.scss';
import vars from '../../../scss/variables.scss';

const NoteRow = ({ note }) => (
  <div className={cn(styles.container)}>
    <div>
      <div className={styles.iconAndTitle}>
        <Icon path={mdiFileDocumentBox} size={vars.iconSizeSmall} />
        <span>Notes</span>
      </div>
    </div>
    <Maybe type={toMaybe(note)}
           just={addedNote =>
             <span className={styles.note}>{addedNote}</span>} />
  </div>
);

NoteRow.propTypes = {
  note: PropTypes.string.isRequired,
};

export default NoteRow;
