import { curry } from 'ramda';
import { toAction, dispatchFetchSuccess, dispatchFetchError } from 'its-js-utility';
import * as actions from './constants';
import { search } from '../../apis';
import { isReinsuranceContextsList, generateUniqueIdsForReinsuranceContexts } from '../../lib';

export const fetchSearchResult = (searchText, searchForFinished, role, forceListResult) =>
  (dispatch, getState) => {
    dispatch(toAction(actions.FETCH_SEARCH_RESULTS));
    return search(searchText, searchForFinished, role, forceListResult)
      .then(maybeContexts => {
        const contexts = maybeContexts.orJust([]);
        const generateYears = getState().app.editMode;
        const preparedContexts = isReinsuranceContextsList(contexts) ? generateUniqueIdsForReinsuranceContexts(contexts, generateYears) : contexts;
        return dispatchFetchSuccess(
          dispatch,
          actions.FETCH_SEARCH_RESULTS_SUCCESS,
          preparedContexts
        );
      })
      .catch(dispatchFetchError(dispatch, actions.FETCH_SEARCH_RESULTS_ERROR));
  };

export const searchTextChanged = curry(
  (dispatch, searchText) =>
    dispatch(toAction(actions.SEARCH_TEXT_CHANGED, searchText))
);

export const clearSearchString = dispatch =>
  () => dispatch(toAction(actions.CLEAR_SEARCH_STRING));

export const resetSearch =
  toAction(actions.RESET_SEARCH);
